import React from "react";
import bakery from "../images/bw-bakery.jpg";
import '../styles/home.css';

export default function Home() {
    return (
        <div>
            <div className="home-header">
                <h1>Our <span>Bagel</span> Story</h1>
            </div>
            <div className="home-desc">
                <div className="home-desc-text">
                    <h2>The Home of Fresh Products</h2>
                    <p>Serving Newmarket and neighboring areas for over 10 years, our bagel restaurant is a beloved local establishment that has become
                        a go-to destination for bagel enthusiasts. With a passion for quality and a commitment to freshness,
                        we take pride in our handcrafted bagels that are baked daily in-house. From classic flavors like plain
                        and sesame to innovative creations like cinnamon raisin and everything bagels, our menu offers a wide range of
                        options to satisfy every craving. Whether you prefer to take-out a simple bagel sandwich made with
                        premium ingredients, or enjoy a sit-in experience in our full service restaurant that serves a large variety of full breakfasts, pancakes, salads, sandwhiches and many other items.
                        Join us and indulge in the comforting aroma of freshly baked bagels, warm hospitality,
                        and a taste that will keep you coming back for more.</p>
                </div>
                <img src={bakery} alt="Bakery" />
            </div>
            <div className="location">
                <div className="location-info">
                    <h2>Visit Us!</h2>
                    <p>We are located at 16995 Yonge Street, Newmarket, Ontario! <br></br><br></br>
                     Our Hours: <br></br><br></br>Monday - Closed<br></br><br></br>Tuesday to Sunday <br></br> 8:30 AM - 2:00 PM</p>
                </div>
                <div className="map-container">
                    <iframe
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2868.011295985217!2d-79.47889898448963!3d44.041822079109856!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x882ad18c6ac1583f%3A0x5aa05c8656718fc4!2sBagel%20World%20Newmarket!5e0!3m2!1sen!2sca!4v1688323758529!5m2!1sen!2sca"
                        width="600"
                        height="450"
                        style={{ border: "0" }}
                        allowFullScreen=""
                        loading="lazy"
                        referrerPolicy="no-referrer-when-downgrade"
                    ></iframe>
                </div>
            </div>

        </div>
    );
}