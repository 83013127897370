import React from "react";

export default function OrderSection(props) {
  return (
    <div className="order-section">
      <h2 className="order-section-title">{props.sectionName}</h2>
      {props.sectionDesc && (
        <div className="order-section-description"
             dangerouslySetInnerHTML={{ __html: props.sectionDesc }}
        />
      )}
      {props.sectionAdd && (
        <div className="order-section-addons">
          {props.sectionAdd.map((addon, index) => (
            <div key={index} className="order-section-addon">
              <span className="order-section-addon-name">{addon.name}</span>
              <span className="order-section-addon-price"> ${addon.price}</span>
            </div>
          ))}
        </div>
      )}
      <br/>
      <div className="order-section-content">{props.children}</div>
    </div>
  );
}
