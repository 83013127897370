import React from "react";
import { Link } from "react-router-dom"; // Import the Link component
import logo from "../images/logo.png";

export default function Navbar() {
  return (
    <nav>
      <ul>
        <Link to="/">  
          <li>
            <img src={logo} alt="Logo" />
          </li>
        </Link>
        <Link to="/">  
          <li>Home</li>
        </Link>
        <Link to="/menu">  
          <li>Menu</li>
        </Link>
        <Link to="/order">  
          <li>Order</li>
        </Link>
      </ul>
    </nav>
  );
}
/**/