import React from "react";
import '../styles/menu.css';

export default function MenuEntry({ name, price, description }) {
    return (
        <div className="menu-entry">
            <div>
              <div className="menu-entry-name">{name}</div>
              {description && <div className="menu-entry-description">{description}</div>}
            </div>
            <div className="menu-entry-price">{price}</div>
        </div>
    );
}
