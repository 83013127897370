let cart = [];

function addToCart(item) {
  cart.push(item);
}

function getCart() {
  return JSON.parse(localStorage.getItem('cart')) || [];
}

module.exports = {
  getCart,
  addToCart
};


