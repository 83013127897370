import React from "react";
import logo from "../images/logo.png";

export default function Footer() {
    return (
        <footer className="footer">
        <div className="footer-content container">
          <div className="footer-section-about">
          <a><img src={logo}/></a>
            <h3>About Us</h3>
            <p>
              Bagel World Newmarket is a family-owned restaurant, determined to provide the best family-friendly experience from our family to yours.
            </p>
          </div>
          <div className="footer-section-links">
            <h3>Quick Links</h3>
            <ul>
              <li><a href="/">Home</a></li>
              <li><a href="menu">Menu</a></li>
              <li><a href="order">Order</a></li>
            </ul>
          </div>
          <div className="footer-section-address">
            <h3>Contact Us</h3>
            <p>Bagel World</p>
            <p>16995 Yonge Street</p>
            <p>Newmarket, ON L3Y 5Y1</p>
            <p>Phone: (905) 235-8000</p>
          </div>
        </div>
        <div className="footer-bottom">
          <p>&copy; 2023 Bagel World</p>
        </div>
      </footer>
    )
}