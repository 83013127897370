import React from "react";
import { useHistory, Link } from "react-router-dom";
import * as menuData from "../data/menuData";
import "../styles/menu.css";
import MenuSection from "../components/MenuSection";
import MenuEntry from "../components/MenuEntry";

export default function Menu() {
  const [menu, setMenu] = React.useState([]);


  function handleClick() {
    window.location.href = "/order";
  }

  React.useEffect(function () {
    const orderedSections = [
      menuData.eggData,
      menuData.benedictData,
      menuData.omeletteData,
      menuData.otherData,
      menuData.platterData,
      menuData.soupData,
      menuData.sandwhichData,
      menuData.wrapData,
      menuData.saladData,
      menuData.bagelliniData,
      menuData.burgerData,
      menuData.sideData,
      menuData.pancakesData,
      menuData.wafflesData,
      menuData.kzData,
      menuData.beverageData
    ];
    setMenu(orderedSections);
  }, []);

  function displayEntries(menu) {
    return menu.map((entry, index) => {
      return (
        <MenuEntry
          key={index}
          className="menu-entry"
          name={entry.name}
          price={entry.price}
          description={entry.description}
        />
      );
    });
  }

/*Right under h1 classname menutitle and right above /div 
 */
  return (
    <div className="menu">
      <div className="menu-header">
        <h1 className="menu-title">Menu</h1>
        <Link to="/order" className="to-order">
        Order Now!
        </Link>
      </div>
      {menu.map((section, index) => {
        return (
          <MenuSection
            key={index}
            className="menu-section"
            sectionName={section.sectionName}
            sectionDesc={section.sectionDesc}
            sectionAdd={section.sectionAdd}
          >
            {displayEntries(section.entries)}
          </MenuSection>

        );
      })}
    </div>
  );
}
