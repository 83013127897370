import React from "react";
import * as menuData from "../data/menuData";
import OrderItem from "../components/order/OrderItem";
import OrderSection from "../components/order/OrderSection";
import Modal from "react-modal";
import { getCart } from "../backend/cart.js";
import "../styles/order.css";
import emailjs from "emailjs-com";

emailjs.init("D44tZqmBPyhuJuuv5");

export default function Order() {
  const [cart, setCart] = React.useState(getCart());
  const [menu, setMenu] = React.useState([]);
  const [showCartModal, setShowCartModal] = React.useState(false);
  const [showCheckoutModal, setShowCheckoutModal] = React.useState(false);
  const [firstName, setFirstName] = React.useState('');
  const [phoneNumber, setPhoneNumber] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [isFormValid, setIsFormValid] = React.useState(false);

  const currentTime = new Date();
  const closingTime = new Date();
  closingTime.setHours(13);
  closingTime.setMinutes(15);

  const openingTime = new Date();
  openingTime.setHours(8);
  openingTime.setMinutes(0);

  const customModalStyles = {
    overlay: {
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      zIndex: 9999
    },
    content: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      maxWidth: '600px',
      width: '100%',
      maxHeight: '80vh',
      height: '100%',
      padding: '20px',
      border: 'none',
      borderRadius: '8px',
      backgroundColor: 'white',
      boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.15)',
      overflow: 'auto'
    }
  };

  function handleCartClick() {
    setShowCartModal(true);
  }

  function handleClearCart() {
    localStorage.removeItem("cart");
    setCart([]);
  }

  function handleRemoveItem(index) {
    const newCart = [...cart];
    newCart.splice(index, 1);
    setCart(newCart);
    localStorage.setItem('cart', JSON.stringify(newCart));
  }

  function calculateTotal(cart) {
    let totalPrice = 0;
    cart.map((item) => (
      totalPrice += item.price
    ))
    return totalPrice;
  }

  const handleFirstNameChange = (event) => {
    setFirstName(event.target.value);
  };

  const handlePhoneNumberChange = (event) => {
    setPhoneNumber(event.target.value);
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  React.useEffect(() => {
    handleFormValidation();
  }, [firstName, phoneNumber, email]);

  const handleFormValidation = () => {
    setIsFormValid(firstName.trim() !== '' && phoneNumber.trim() !== '' && email.trim() !== '');
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    handleFormValidation();

    if (isFormValid) {
      sendEmail(event);
      setShowCheckoutModal(!showCheckoutModal);
      handleClearCart();
      
    } else {
      alert('Please fill in all the required fields.');
    }
  };

  function sendEmail(event) {
    event.preventDefault();
    let msgInfo = "";
    cart.map((item) => {
      let msgItem = "";
      if (item.name) {
        msgItem += `${item.name}  ($${item.basePrice.toFixed(2)})\n`;
      }
      if (item.proteinQuantitiesToSet) {
        {
          Object.entries(item.proteinQuantitiesToSet).map(([name, quantity]) => (
            msgItem += `${quantity} x ${name}\n`
          ))
        }
      }
      if (item.proteinChoiceName) {
        msgItem += `${item.proteinChoiceName}\n`;
      }
      if (item.sectionOptionName) {
        msgItem += `${item.sectionOptionName}\n`;
      }
      if (item.eggServing) {
        msgItem += `${item.eggServing}\n`;
      }
      if (item.steak) {
        msgItem += `${item.steak}\n`;
      }
      if (item.oatmeal) {
        msgItem += `${item.oatmeal}\n`;
      }
      if (item.sectionToastName) {
        msgItem += `${item.sectionToastName}\n`;
      }
      if (item.sectionToastLevel) {
        msgItem += `${item.sectionToastLevel}\n`;
      }
      if (item.butter) {
        msgItem += `${item.butter}\n`;
      }
      if (item.secondToastName) {
        msgItem += `${item.secondToastName}\n`;
      }
      if (item.secondToastLevel) {
        msgItem += `${item.secondToastLevel}\n`;
      }
      if (item.secondButter) {
        msgItem += `${item.secondButter}\n`;
      }
      if (item.sectionAdditions) {
        item.sectionAdditions.map((addition) => {
          msgItem += `${addition.label}\n`;
        });
      }
      if (item.price) {
        msgItem += `$${item.price.toFixed(2)}\n`;
      }
      if (msgItem !== "") {
        msgInfo += msgItem + "\n";
      }
    });
    let subtotal = (calculateTotal(cart)).toFixed(2);
    let tax = (calculateTotal(cart) * 0.13).toFixed(2);
    let total = (calculateTotal(cart) * 1.13).toFixed(2);
    emailjs.send('service_1sagl7l', 'template_8uxyq8c', {
      to_name: firstName,
      phone_num: phoneNumber,
      message: msgInfo,
      subtotal: subtotal,
      tax: tax,
      total_price: total,
      to_email: email
    }, 'D44tZqmBPyhuJuuv5')
      .then((result) => {
        alert("Your Order Has Been Successfully Sent! Please Check Your Email For the Order And Be Ready For a Call From Us Soon!");
        console.log(result.text);
      }, (error) => {
        alert("Order Failed, Please Try Again Later");
        console.log(error.text);
      });
  }



  React.useEffect(function () {
    const orderedSections = [
      menuData.eggData,
      menuData.benedictData,
      menuData.omeletteData,
      menuData.otherData,
      menuData.platterData,
      menuData.soupData,
      menuData.sandwhichData,
      menuData.wrapData,
      menuData.saladData,
      menuData.bagelliniData,
      menuData.burgerData,
      menuData.sideData,
      menuData.pancakesData,
      menuData.wafflesData,
      menuData.kzData,
      menuData.beverageData
    ];
    const cartData = JSON.parse(localStorage.getItem('cart')) || [];
    setCart(cartData);
    setMenu(orderedSections);
  }, []);

  function displayEntries(entries, sectionAdd, sectionToast, sectionOption, eggServing) {
    return entries.map((entry, index) => {
      return (
        <OrderItem
          key={index}
          className="order-entry"
          name={entry.name}
          price={entry.price}
          proteinChoice={entry.proteinChoice}
          proteins={entry.proteins}
          proteinLimit={entry.proteinLimit}
          extraAllowed={entry.extraAllowed}
          isSteak={entry.isSteak}
          secondToast={entry.secondToast}
          isOatmeal={entry.isOatmeal}
          noToasting={entry.noToasting}
          noBread={entry.noBread}
          noside={entry.noside}
          description={entry.description}
          mealAddOn={entry.mealAddOn}
          sectionAdd={sectionAdd}
          sectionToast={sectionToast}
          sectionOption={sectionOption}
          eggServing={eggServing}
          addToCart={addToCart}
        />
      );
    });
  }

  function addToCart(item) {
    const cartData = JSON.parse(localStorage.getItem('cart')) || [];
    cartData.push(item);
    localStorage.setItem('cart', JSON.stringify(cartData));
    setCart(cartData);
  }

  if (currentTime.getDay() != 1 && currentTime < closingTime && currentTime > openingTime) {

    return (
      <div>

        <div className="cart-button-container">
          <button className="cart-button view-cart-button" onClick={handleCartClick}>
            View Cart ({cart.length})
          </button>
        </div>


        <div className="header">
          <div className="header-content">
            <h1 className="header-title">Skip The Line</h1>
            <p className="header-description">
              Order online and have fresh and delicious meal ready to go when you come in.
            </p>
          </div>
        </div>

        <div className="order-menu">
          <h1 className="order-title">Menu</h1>
          {menu.map((section, index) => {
            return (
              <OrderSection
                key={index}
                className="order-section"
                sectionName={section.sectionName}
                sectionDesc={section.sectionDesc}
                sectionAdd={section.sectionAdd}
                sectionToast={section.sectionToast}
                sectionOption={section.sectionOption}
                eggServing={section.eggServing}
              >
                {displayEntries(section.entries, section.sectionAdd, section.sectionToast, section.sectionOption, section.eggServing)}
              </OrderSection>
            );


          })}
          <h1>For Catering Inquiries Please Call Us At (905) 235 - 8000</h1>
        </div>
        {showCartModal && (
          <Modal
            isOpen={true}
            ariaHideApp={false}
            onRequestClose={() => setShowCartModal(false)}
            style={customModalStyles}
          >
            <div className="cart-modal">
              {showCheckoutModal ? (
                <div className="checkout">
                  <form onSubmit={handleSubmit}>
                    <label>
                      Name:
                      <input type="text" value={firstName} onChange={handleFirstNameChange} />
                    </label>
                    <label>
                      Phone Number:
                      <input type="tel" value={phoneNumber} onChange={handlePhoneNumberChange} />
                    </label>
                    <label>
                      Email:
                      <input type="email" value={email} onChange={handleEmailChange} />
                    </label>
                    <button className="place-order" type="submit">Place Order</button>
                  </form>
                  <button onClick={() => setShowCheckoutModal(!showCheckoutModal)}>Back</button>
                </div>
              ) : (
                <>
                  <h2 className="cart-title">Cart</h2>
                  {cart.map((item, index) => (
                    <div className="cart-item" key={index}>
                      <p>{item.name}</p>
                      <p>{item.eggServing}</p>
                      <p>{item.proteinChoiceName}</p>
                      {item.proteinQuantitiesToSet && (
                        <ul>
                          {Object.entries(item.proteinQuantitiesToSet).map(([name, quantity]) => (
                            <li key={name}>{`${quantity} x ${name}`}</li>
                          ))}
                        </ul>
                      )}
                      <p>{item.sectionOptionName}</p>
                      <p>{item.steak}</p>
                      <p>{item.oatmeal}</p>
                      <p>{item.sectionToastName}</p>
                      <p>{item.sectionToast}</p>
                      <p>{item.sectionToastLevel}</p>
                      <p>{item.butter}</p>
                      <p>{item.secondToastName}</p>
                      <p>{item.secondToast}</p>
                      <p>{item.secondToastLevel}</p>
                      <p>{item.secondButter}</p>
                      {item.sectionAdditions && (
                        <ul>
                          {item.sectionAdditions.map((addition, i) => (
                            <li key={i}>{addition.label}</li>
                          ))}
                        </ul>
                      )}
                      <p>${item.price.toFixed(2)}</p>
                      <button className="remove-item-button" onClick={() => handleRemoveItem(index)}>
                        Remove
                      </button>
                    </div>
                  ))}
                  {cart.length === 0 && <h1>No Items in Cart!</h1>}
                  <br />
                  <button className="clear-cart-button" onClick={handleClearCart}>
                    Clear Cart
                  </button>
                  <button className="close-modal-button" onClick={() => setShowCartModal(false)}>
                    Close
                  </button>
                  {cart.length > 0 && <button className="checkout-button" onClick={() => setShowCheckoutModal(!showCheckoutModal)}>
                    Checkout
                  </button>}
                </>

              )}
              <div className="total-price">
                <label className="price-label">Subtotal: </label><label className="price-number">${calculateTotal(cart).toFixed(2)} <br></br></label>
                <label className="price-label">Tax: </label><label className="price-number">${(calculateTotal(cart) * 0.13).toFixed(2)} <br></br></label>
                <label className="price-label">Total: </label><label className="price-number">${(calculateTotal(cart) * 1.13).toFixed(2)} <br></br></label>
              </div>
            </div>
          </Modal>

        )}
      </div>
    );
  }
  else {
    return(
    <div className="closed-message">
      <h1>Sorry! We are Closed for the day!</h1>
      <h2>Our Kitchen is open between 8:00 AM and 1:30 PM. Please Come Back Tomorrow!</h2>
      <h4>You can also find our hours in the home page</h4>
    </div>
    );
  }
}