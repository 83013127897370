import React, { useState } from "react";
import Modal from "react-modal";
import Select from 'react-select';

export default function OrderItem(props) {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState([]);
  const [buttonLabel, setButtonLabel] = useState(`Add to Cart (+$${props.price.toFixed(2)})`);
  const [proteinQuantities, setProteinQuantities] = useState({});
  const proteinLimit = props.proteinLimit;
  const [proteinStyles, setProteinStyles] = useState({});
  const [proteinIndicator, setProteinIndicator] = useState(null);
  const [toastSelection, setToastSelection] = useState(null);
  const [secondToastSelection, setSecondToastSelection] = useState(null);
  const [optionSelection, setOptionSelection] = useState(null);
  const [proteinOptionSelection, setproteinOptionSelection] = useState(null);
  const [proteinCounter, setProteinCounter] = useState(null);

  const handleStyleChange = (proteinName, style) => {
    setProteinStyles((prevState) => {
      const updatedStyles = { ...prevState };
      if (updatedStyles[proteinName] === style) {
        updatedStyles[proteinName] = '';
      } else {
        updatedStyles[proteinName] = style;
      }
      return updatedStyles;
    });
  };

  //
  const customModalStyles = {
    overlay: {
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      zIndex: 9999
    },
    content: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      maxWidth: '600px',
      width: '100%',
      maxHeight: '80vh',
      height: '100%',
      padding: '20px',
      border: 'none',
      borderRadius: '8px',
      backgroundColor: 'white',
      boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.15)',
      overflow: 'auto'
    }
  };

  // Check if the item is Shakshuka and override the eggServing value
  let eggServing = props.eggServing;
  let sectionToast = props.sectionToast;
  if (props.name === "Shakshuka") {
    eggServing = [{
      "name": "Poached Soft"
    },
    {
      "name": "Poached Medium"
    },
    {
      "name": "Poached Hard"
    }];
  }
  else if (props.name === "Kid's Breakfast") {
    eggServing = [{
      "name": "Scrambled"
    },
    {
      "name": "Scrambled Loose"
    },
    {
      "name": "Over Easy"
    },
    {
      "name": "Over Medium "
    },
    {
      "name": "Over Hard"
    },
    {
      "name": "Sunny Side Up"
    },
    {
      "name": "Omelette Style"
    },
    {
      "name": "Poached Soft"
    },
    {
      "name": "Poached Medium"
    },
    {
      "name": "Poached Hard"
    },
    {
      "name": "No Egg"
    }
    ];
    sectionToast = [{
      "name": "White Bread",
      "price": 0
  },
  {
      "name": "Whole Wheat (Brown) Bread",
      "price": 0
  },
  {
      "name": "Rye Bread",
      "price": 0
  },
  {
      "name": "Challah Bread",
      "price": 0
  },
  {
      "name": "Plain Mini-Bagel",
      "price": 0
  },
  {
      "name": "Sesame Mini-Bagel",
      "price": 0
  }]
  }
  else if (props.name === "Grilled Cheese Sandwhich") {
    sectionToast = [{
      "name": "White Bread",
      "price": 0
  },
  {
      "name": "Whole Wheat (Brown) Bread",
      "price": 0
  },
  {
      "name": "Rye Bread",
      "price": 0
  },
  {
      "name": "Challah Bread",
      "price": 0
  }]
  }

  const updateProteinIndicator = (quantities, limit) => {
    const totalProtein = quantities;
    const overLimit = totalProtein > limit;
    const overAmount = overLimit ? totalProtein - limit : 0;
    setProteinIndicator(overAmount);
  };

  const handleIncrement = (protein) => {
    const currentTotalProteins = Object.values(proteinQuantities).reduce((sum, quantity) => sum + quantity, 0);
    const extraAllowed = props.extraAllowed || false;

    if (currentTotalProteins >= proteinLimit && !extraAllowed) {
      return;
    }

    const proteinName = protein.includes(' (') ? protein.substring(0, protein.indexOf(' (')) : protein;
    const proteinStyle = protein.includes(' (') ? protein.substring(protein.indexOf(' (') + 2, protein.length - 1) : null;

    if (!extraAllowed && proteinName === 'Ham' && (proteinQuantities['Ham'] || 0) >= 2) {
      return;
    }

    if (proteinStyle) {
      setProteinQuantities((prevQuantities) => ({
        ...prevQuantities,
        [proteinName]: {
          ...prevQuantities[proteinName],
          [proteinStyle]: (prevQuantities[proteinName]?.[proteinStyle] || 0) + 1,
        },
      }));
    } else {
      setProteinQuantities((prevQuantities) => ({
        ...prevQuantities,
        [proteinName]: (prevQuantities[proteinName] || 0) + 1,
      }));
    }

    updateProteinIndicator(currentTotalProteins + 1, proteinLimit);
    setProteinCounter(currentTotalProteins + 1);
  };

  const handleDecrement = (protein) => {
    const currentTotalProteins = Object.values(proteinQuantities).reduce((sum, quantity) => sum + quantity, 0);
    setProteinQuantities((prevQuantities) => ({
      ...prevQuantities,
      [protein]: Math.max((prevQuantities[protein] || 0) - 1, 0),
    }));

    if (proteinQuantities[protein] > 0) {
      updateProteinIndicator(currentTotalProteins - 1, proteinLimit);
      setProteinCounter(currentTotalProteins - 1);
    }
  };

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const handleChange = (selectedOption) => {
    setSelectedOption(selectedOption);
  };

  const handleToastChange = (event) => {
    const toastPrice = event.target.value;
    setToastSelection(toastPrice);
  };

  const handleSecondToastChange = (event) => {
    const secondToastPrice = event.target.value;
    setSecondToastSelection(secondToastPrice);
  };

  const handleOptionChange = (event) => {
    const optionPrice = event.target.value.split('|')[0];
    setOptionSelection(optionPrice);
  };

  const handleProteinChange = (event) => {
    const optionPrice = event.target.value.split('|')[0];
    setproteinOptionSelection(optionPrice);
  }

  React.useEffect(() => {
    updateButtonLabel(selectedOption);
  }, [selectedOption, proteinIndicator, toastSelection, secondToastSelection, optionSelection, proteinOptionSelection]);

  const updateButtonLabel = (selectedOption, toastPrice = null, secondToastPrice = null, optionPrice = null) => {
    let totalPrice = parseFloat(props.price);

    // Calculate section additions price
    if (selectedOption.length > 0) {
      const additionsPrice = selectedOption.reduce((sum, option) => sum + parseFloat(option.value), 0);
      totalPrice += additionsPrice;
    }

    if (toastSelection != null) {
      totalPrice += parseFloat(toastSelection);
    }
    if (secondToastSelection != null) {
      totalPrice += parseFloat(secondToastSelection);
    }
    if (optionSelection != null) {
      totalPrice += parseFloat(optionSelection);
    }
    if (proteinOptionSelection != null) {
      totalPrice += parseFloat(proteinOptionSelection);
    }

    if (proteinIndicator != null) {
      totalPrice += parseFloat(proteinIndicator * 1.25);
    }

    setButtonLabel(`Add to Cart (+$${totalPrice.toFixed(2)})`);
  };

  const sectionAdditions = (sectionAdd) => {
    if (!sectionAdd) {
      return [];
    }

    return sectionAdd.map((addOn) => ({
      value: addOn.price,
      label: `${addOn.name} (+$${addOn.price})`
    }));
  };

  const mealAddOns = (mealAddOn) => {
    if (!mealAddOn) {
      return [];
    }

    return mealAddOn.map((addOn) => ({
      value: addOn.price,
      label: `${addOn.label} (+$${addOn.price})`
    }));
  };

  const resetOptions = () => {
    setSelectedOption([]);
    setProteinQuantities({});
    setProteinStyles({});
    setProteinIndicator(null);
    setToastSelection(null);
    setSecondToastSelection(null);
    setOptionSelection(null);
    setproteinOptionSelection(null);
  };

  function handleAddToCart() {
    const eggServingSelection = document.querySelector('select[name="eggServing"]');
    const eggServing = eggServingSelection?.options[eggServingSelection.selectedIndex]?.text || null;

    const sectionOption = document.querySelector('select[name="sectionOption"]');
    const sectionOptionName = sectionOption?.options[sectionOption.selectedIndex]?.text || null;
    const sectionOptionPrice = sectionOption?.value?.split('|')[0] || null;

    const sectionToast = document.querySelector('select[name="sectionToast"]');
    const sectionToastName = sectionToast?.options[sectionToast.selectedIndex]?.text || null;
    const sectionToastPrice = sectionToast?.value || null;

    const secondToast = document.querySelector('select[name="secondToast"]');
    const secondToastName = secondToast?.options[secondToast.selectedIndex]?.text || null;
    const secondToastPrice = secondToast?.value || null;

    const proteinChoice = document.querySelector('select[name="proteinChoice"]');
    const proteinChoiceName = proteinChoice?.options[proteinChoice.selectedIndex]?.text || null;
    const proteinChoicePrice = proteinChoice?.value || null;

    let price = parseFloat(props.price);

    // Calculate section options price
    if (sectionOptionPrice) {
      price += parseFloat(sectionOptionPrice);
    }

    if (proteinIndicator != null) {
      price += parseFloat(proteinIndicator * 1.25);
    }

    // Calculate section toast price
    if (sectionToastPrice) {
      price += parseFloat(sectionToastPrice);
    }

    // Calculate second toast price
    if (secondToastPrice) {
      price += parseFloat(secondToastPrice);
    }

    if (proteinChoicePrice) {
      price += parseFloat(proteinChoicePrice);
    }

    // Calculate section additions price
    let additionsPrice = 0;
    if (selectedOption.length > 0) {
      additionsPrice = selectedOption.reduce((sum, option) => sum + parseFloat(option.value), 0);
    }

    const proteinQuantitiesToSet = Object.keys(proteinQuantities).reduce((acc, proteinName) => {
      if (proteinQuantities[proteinName] > 0) {
        const style = proteinStyles[proteinName] || '';
        const name = style ? `${proteinName} (${style})` : proteinName;
        acc[name] = proteinQuantities[proteinName];
      }
      return acc;
    }, {});

    price += additionsPrice;

    const selectedItem = {
      name: props.name,
      basePrice: props.price,
      price: price,
      sectionToastLevel: document.querySelector('input[name="toast"]:checked')?.value || null,
      eggServing: eggServing,
      butter: document.querySelector('input[name="butter"]:checked')?.value || null,
      secondToastLevel: document.querySelector('input[name="secondToast"]:checked')?.value || null,
      secondButter: document.querySelector('input[name="secondButter"]:checked')?.value || null,
      steak: document.querySelector('input[name="steak"]:checked')?.value || null,
      oatmeal: document.querySelector('input[name="oatmeal"]:checked')?.value || null,
      sectionOptionName,
      sectionOptionPrice,
      sectionToastName,
      sectionToastPrice,
      secondToastName,
      secondToastPrice,
      proteinChoiceName,
      proteinChoicePrice,
      proteinQuantitiesToSet,
      sectionAdditions: selectedOption.length > 0 ? selectedOption : null,
    };

    props.addToCart(selectedItem);
    setModalIsOpen(false);
    alert("Added Item To Cart!");
    resetOptions();
  }

  return (
    <div className="order-item">
      <h4>{props.name}</h4>
      <p className="item-price">${props.price}</p>
      <button className="view-item-button" onClick={openModal}>
        View Item
      </button>
      <Modal isOpen={modalIsOpen} onRequestClose={closeModal} style={customModalStyles} ariaHideApp={false}>
        <div className="modal-content">
          <h2>{props.name}</h2>
          <p>{props.description}</p>
          <p>${props.price}</p>
          {eggServing && (
            <div>
              <h3>How Would You Like Your Eggs Done?</h3>
              <select name="eggServing">
                {eggServing.map((option, index) => (
                  <option key={index} value={`${option.name}`}>
                    {option.name}
                  </option>
                ))}

              </select>
            </div>
          )}
          {props.isOatmeal && <div>
            <h1>oatmeal</h1>
            <label>Oatmeal Style:</label>
            <div>
              <label>
                <input type="radio" name="oatmeal" value="Runny" /> Runny
              </label>
            </div>
            <div>
              <label>
                <input type="radio" name="oatmeal" value="Medium" defaultChecked /> Medium
              </label>
            </div>
            <div>
              <label>
                <input type="radio" name="oatmeal" value="Thick" /> Thick
              </label>
            </div>
          </div>}
          {props.proteinChoice && (
            <div>
              <h3>Please Choose One of The Following:</h3>
              <select name="proteinChoice" onChange={handleProteinChange}>
                {props.proteinChoice.map((option, index) => (
                  <option key={index} value={`${option.price}|${option.name}`}>
                    {option.name}
                  </option>
                ))}
              </select>
            </div>
          )}

          {props.proteins && (
            <div>
              <h3>Protein Options:</h3>
              <div className="protein-grid">
                {props.proteins.map((protein, index) => (
                  <div key={protein.name} className="protein-option">
                    <span>{proteinStyles[protein.name] || protein.name}</span>
                    <div className="button-container">
                      <button onClick={() => handleDecrement(protein.name)}>-</button>
                      <span>{proteinQuantities[protein.name] || 0}</span>
                      <button
                        onClick={() => handleIncrement(protein.name)}
                        style={{
                          backgroundColor: proteinCounter >= proteinLimit && !props.extraAllowed ? 'gray' : '#0069d9',
                          cursor: proteinCounter >= proteinLimit && !props.extraAllowed ? 'default' : 'pointer',
                        }}
                      >
                        +
                      </button>
                    </div>
                    {protein.styles && proteinQuantities[protein.name] > 0 && (
                      <div>
                        {protein.styles.map((style) => (
                          <label key={style}>
                            <input
                              type="checkbox"
                              checked={proteinStyles[protein.name] === style}
                              onChange={() => handleStyleChange(protein.name, style)}
                            />
                            {style}
                          </label>
                        ))}
                      </div>
                    )}
                  </div>
                ))}
                {proteinIndicator && proteinIndicator > 0 ? <p className="protein-indicator">{proteinIndicator} Extra selected (+${proteinIndicator * 1.45})</p> : null}
              </div>
            </div>
          )}

          {sectionToast && (
            <div>
              {!props.noBread &&
                <div>
                  <h3>Choice of Bread:</h3>
                  <select name="sectionToast" onChange={handleToastChange}>
                    {sectionToast.map((option, index) => (
                      <option key={index} value={option.price}>
                        {option.name}
                      </option>
                    ))}
                  </select>
                </div>}
              {props.isSteak && <div>
                <label>Steak Level:</label>
                <div>
                  <label>
                    <input type="radio" name="steak" value="Rare" /> Rare
                  </label>
                </div>
                <div>
                  <label>
                    <input type="radio" name="steak" value="Medium-Rare" defaultChecked /> Medium-Rare
                  </label>
                </div>
                <div>
                  <label>
                    <input type="radio" name="steak" value="Medium" /> Medium
                  </label>
                </div>
                <div>
                  <label>
                    <input type="radio" name="steak" value="Medium-Well" /> Medium-Well
                  </label>
                </div>
                <div>
                  <label>
                    <input type="radio" name="steak" value="Well-Done" /> Well-Done
                  </label>
                </div>
              </div>}
              {!props.noToasting && <div><div>
                <label>Toast:</label>
                <div>
                  <label>
                    <input type="radio" name="toast" value="lightly-toasted" /> Lightly Toasted
                  </label>
                </div>
                <div>
                  <label>
                    <input type="radio" name="toast" value="toasted" defaultChecked /> Toasted
                  </label>
                </div>
                <div>
                  <label>
                    <input type="radio" name="toast" value="heavy-toasted" /> Heavy Toasted
                  </label>
                </div>
                <div>
                  <label>
                    <input type="radio" name="toast" value="not-toasted" /> Not Toasted
                  </label>
                </div>
              </div>
                <div>
                  <label>Butter:</label>
                  <div>
                    <label>
                      <input type="radio" name="butter" value="buttered" /> Buttered
                    </label>
                  </div>
                  <div>
                    <label>
                      <input type="radio" name="butter" value="not-buttered" defaultChecked /> Not Buttered
                    </label>
                  </div>
                </div>
              </div>}
            </div>
          )}
          {props.secondToast && sectionToast && (
            <div>
              {!props.noBread &&
                <div>
                  <h3>Second Choice of Bread:</h3>
                  <select name="secondToast" onChange={handleSecondToastChange}>
                    {sectionToast.map((option, index) => (
                      <option key={index} value={option.price}>
                        {option.name}
                      </option>
                    ))}
                  </select>
                </div>}
              {!props.noToasting && <div><div>
                <label>Toast:</label>
                <div>
                  <label>
                    <input type="radio" name="secondToast" value="lightly-toasted" /> Lightly Toasted
                  </label>
                </div>
                <div>
                  <label>
                    <input type="radio" name="secondToast" value="toasted" defaultChecked /> Toasted
                  </label>
                </div>
                <div>
                  <label>
                    <input type="radio" name="secondToast" value="heavy-toasted" /> Heavy Toasted
                  </label>
                </div>
                <div>
                  <label>
                    <input type="radio" name="secondToast" value="not-toasted" /> Not Toasted
                  </label>
                </div>
              </div>
                <div>
                  <label>Butter:</label>
                  <div>
                    <label>
                      <input type="radio" name="secondButter" value="buttered" /> Buttered
                    </label>
                  </div>
                  <div>
                    <label>
                      <input type="radio" name="secondButter" value="not-buttered" defaultChecked /> Not Buttered
                    </label>
                  </div>
                </div>
              </div>}
            </div>
          )}

          {props.sectionOption && !props.noside && (
            <div>
              <h3>Please Choose One of The Following:</h3>
              <select name="sectionOption" onChange={handleOptionChange}>
                {props.sectionOption.map((option, index) => (
                  <option key={index} value={`${option.price}|${option.name}`}>
                    {option.name}
                  </option>
                ))}
              </select>
            </div>
          )}

          {(props.sectionAdd || props.mealAddOn) && (
            <div>
              <h3>Add-ons:</h3>
              <Select
                isMulti={true}
                value={selectedOption}
                onChange={handleChange}
                options={[
                  ...sectionAdditions(props.sectionAdd),
                  ...mealAddOns(props.mealAddOn)
                ]}
              />

            </div>
          )}
          <br></br>

          <button className="close-modal-button" onClick={closeModal}>
            Cancel
          </button>
          <button className="add-cart-button" onClick={handleAddToCart}>
            {buttonLabel}
          </button>

        </div>
      </Modal>
    </div>
  );
}