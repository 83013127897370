import React from "react";
import "../styles/menu.css";

export default function MenuSection(props) {
  return (
    <div className="menu-section">
      <h2 className="menu-section-title">{props.sectionName}</h2>
      {props.sectionDesc && (
        <div className="menu-section-description"
             dangerouslySetInnerHTML={{ __html: props.sectionDesc }}
        />
      )}
      {props.sectionAdd && (
        <div className="menu-section-addons">
          {props.sectionAdd.map((addon, index) => (
            <div key={index} className="menu-section-addon">
              <span className="menu-section-addon-name">{addon.name}</span>
              <span className="menu-section-addon-price"> ${addon.price}</span>
            </div>
          ))}
        </div>
      )}
      <br/>
      <div className="menu-section-content">{props.children}</div>
    </div>
  );
}
